import { createRef } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Utils */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import history from "../../helpers/history";
import { checkString } from "../../helpers/utils";
import { success, error } from "../../helpers/toast";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Components */
import OnChange from "../../components/OnChange";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class MailBox extends OnChange {
    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            message: "",
        };
        this.formValidation = this.formValidation.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.inputTitleRef = createRef();
        this.textareaMessageRef = createRef();
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Mail Box",
            metaDescription: "",
        });
    }

    formValidation() {
        const { subject, message } = this.state;
        const { name, surname, uid, canteen_id } = this.props.user;
        if (!checkString(subject)) {
            this.inputTitleRef?.current?.focus();
            return false;
        }
        if (!checkString(message) || message.length < 10) {
            this.textareaMessageRef?.current?.focus();
            return false;
        }
        return checkString(name) && checkString(surname) && checkString(uid) && checkString(canteen_id);
    }

    async sendMessage(e) {
        e.preventDefault();
        const { subject, message } = this.state;
        const { name, surname, uid, canteen_id } = this.props.user;
        try {
            this.props.activateLoader();
            if (this.formValidation()) {
                const result = await this.context.api.initChat({
                    subject,
                    message,
                    name,
                    surname,
                    uid,
                    canteen_id,
                });

                if (result) {
                    success(this.props.t("mail_box.send"));
                    history.pushWithCheck(INDEX_URL + PAGES.MESSAGES);
                } else {
                    error(this.props.t("mail_box.error_send"));
                }
            } else {
                error(this.props.t("mail_box.error_parameters"));
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    renderMailBoxForm() {
        return (
            <>
                <form>
                    {/*Mail*/}
                    <Input
                        innerRef={this.inputTitleRef}
                        type="text"
                        className="form-control"
                        id="mail_box_input_subject"
                        placeholder={this.props.t("mail_box.subject")}
                        value={this.state.subject}
                        onChange={e => this.onChange(e, "subject")}
                    />
                    <div className="form-group">
                        <label htmlFor="mensaje">{this.props.t("mail_box.message")}</label>
                        {/**
								placeholder={this.props.t('mail_box.message')}
							*/}
                        <textarea
                            ref={this.textareaMessageRef}
                            id="mail_box_textarea_message"
                            className="form-control minheight"
                            value={this.state.message}
                            onChange={e => this.onChange(e, "message")}
                        />
                    </div>
                    <div className="form-group">
                        <div className="btns-area">
                            <button type="submit" className="btn btn-primary" onClick={this.sendMessage}>
                                {this.props.t("mail_box.send_message")}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    render() {
        return (
            <>
                <Header />
                <section className="middle-content">
                    <div className="container form-area maxwidth">
                        <div className="head-area">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="default-heading text-uppercase">
                                        {" "}
                                        <h2>
                                            {" "}
                                            <strong>{this.props.t("mail_box.title")}</strong>{" "}
                                        </h2>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12">{this.renderMailBoxForm()}</div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

MailBox.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MailBox));
