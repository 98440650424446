/**Packages */
import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/**Helpers */
import {
    INDEX_URL,
    PAGES,
    SWITCH_MODE,
    STATIC_PAGES,
    PAGES_WHERE_SHOW_USER_ICON,
    SOHAPPY_GAMES_URL,
} from "../../helpers/constants";
import {
    checkIfProfileEquals,
    sortByPosAscending,
    getActivePage,
    getCurrentLanguage,
    openUrl,
    openUrlInSamePage,
} from "../../helpers/utils";
import {
    getProfilesIcon,
    getIfToggleSwitchModeIsEnabled,
    getHeaderLinksByEnviroment,
    getIfAvatarModeIsEnabled,
    getIfLogoCanteenIsVisible,
    getIfUseProfileSimplifyVersion,
} from "../../helpers/enviroment";
import { returnToParentMode, toggleView, checkIfChildrenModeIsActivaded } from "../../helpers/view";
import { disableOverflowY, enableOverflowY } from "../../helpers/overflow";
import { getIfSchoolNameUnderLogoIsEnabled } from "../../helpers/enviroment";
import { getAndOpenStaticPageByUrl } from "../../helpers/url";
import { getAvatarImage } from "../../helpers/assets";
import { getWindowSize } from "../../helpers/screen";
import { getAvatarHtml } from "../../helpers/avatar";
import history from "../../helpers/history";

/**Components */
import { AvatarList } from "../AvatarList";
import { CustomLink } from "../CustomLink";
import { Version } from "../Version";
import Profile from "../Profile";
import Margin from "../Margin";

/**Images */
import logoMiddle from "../../images/logo-middle.svg";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { USER_LOGGED_OUT, USER_SELECTED_PROFILE } from "../../redux/actions/userActions";
import { SET_CANTEEN_DATA, DELETE_CANTEEN_DATA } from "../../redux/actions/canteenActions";
import { USER_UPDATE_AVATAR_PROFILE } from "../../redux/actions/userActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_header_custom.scss";

const dropdownMenuBaseCssClass = "dropdown-menu";
const dropdownMenuActiveCssClass = dropdownMenuBaseCssClass + " show";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canteenData: this.props.canteen ? this.props.canteen : null,
            userIconClicked: false,
            dropdownMenuCssClass: dropdownMenuBaseCssClass,
        };
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.getProfilesHtml = this.getProfilesHtml.bind(this);
        this.onProfileSelection = this.onProfileSelection.bind(this);
        this.updateAvatarProfile = this.updateAvatarProfile.bind(this);
        this.onChildClick = this.onChildClick.bind(this);
        this.getHeaderLinks = this.getHeaderLinks.bind(this);
        this.toggleUserIconClick = this.toggleUserIconClick.bind(this);
        this.logout = this.logout.bind(this);

        this.accountAreaRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (
            this.accountAreaRef &&
            this.accountAreaRef.current &&
            !this.accountAreaRef.current.contains(event.target) &&
            this.state.userIconClicked
        ) {
            enableOverflowY();
            this.setState({
                userIconClicked: false,
                dropdownMenuCssClass: dropdownMenuBaseCssClass,
            });
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleSidebar(e) {
        enableOverflowY();

        /**Items to be moved */
        $(".sidebar").toggleClass("shrink");

        if (getIfToggleSwitchModeIsEnabled()) {
            $(".rate-recipe-ul-container").toggleClass("shrink");
        }
    }

    logout(e) {
        this.toggleSidebar(e);
        try {
            this.props.activateLoader();
            this.context.logout();
            this.props.logout();
            enableOverflowY();
            returnToParentMode();
            history.pushWithCheck(INDEX_URL + PAGES.LOGIN);
        } catch (e) {
            console.log("e");
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    async onProfileSelection(e, profileSelected) {
        if (!profileSelected) return;

        await this.context?.api?.getCanteenData(profileSelected?.cid);
        await this.props.setSelectedProfile(profileSelected, this.context);

        enableOverflowY();

        this.setState({
            dropdownMenuCssClass: dropdownMenuBaseCssClass,
        });
    }

    async updateAvatarProfile(e, selectedAvatar) {
        this.props.activateLoader();
        try {
            if (selectedAvatar) {
                const docId = this.props.user.selectedProfile.docId;
                if (docId) {
                    const result = await this.context.api.updateProfileAvatar(docId, selectedAvatar);
                    if (result) {
                        this.props.updateAvatarProfile(docId, selectedAvatar);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({
                dropdownMenuCssClass: dropdownMenuBaseCssClass,
            });
            this.props.deactivateLoader();
        }
    }

    async onChildClick(e, profileSelected) {
        //e.preventDefault();
        //e.stopPropagation();

        if (profileSelected) {
            const activePage = getActivePage();

            if (!checkIfProfileEquals(this.props.user.selectedProfile, profileSelected)) {
                await this.context?.api?.getCanteenData(profileSelected?.cid);
                await this.props.setSelectedProfile(profileSelected, this.context);
            }

            if (activePage !== PAGES.HOME && activePage.length !== 1) {
                history.pushWithCheck(INDEX_URL + PAGES.HOME);
            }

            toggleView();

            const self = this;

            self.setState({
                dropdownMenuCssClass: dropdownMenuBaseCssClass,
            });
            setTimeout(() => {
                self.setState({});
            }, 500);
        }
    }

    getProfilesHtml(selectedProfile, profiles) {
        const profilesHtml = [
            <Profile
                key={"selected-profile"}
                profile={selectedProfile}
                onClick={e => this.onProfileSelection(e, selectedProfile)}
                isCurrentSelected={true}
                useLittle={false}
                characterLimits={32}
                useSimplifyVersion={getIfUseProfileSimplifyVersion()}
                onChildClick={this.onChildClick}
            />,
        ];
        let i = 0;
        profilesHtml.push(
            ...profiles?.sort(sortByPosAscending).map(profile => {
                if (!checkIfProfileEquals(selectedProfile, profile) && i < 2) {
                    i++;
                    return (
                        <Profile
                            key={"profile_" + i}
                            profile={profile}
                            onClick={e => this.onProfileSelection(e, profile)}
                            isCurrentSelected={checkIfProfileEquals(this.props.user.selectedProfile, profile)}
                            useLittle={false}
                            characterLimits={32}
                            useSimplifyVersion={getIfUseProfileSimplifyVersion()}
                            onChildClick={this.onChildClick}
                        />
                    );
                } else {
                    return null;
                }
            }),
        );

        return profilesHtml;
    }

    getHeaderLinks(selectedProfile = null) {
        return getHeaderLinksByEnviroment().map((item, idx, arr) => {
            if (item === null || item === undefined || typeof item !== "string") return null;

            let itemToReturn = null;
            const activePage = getActivePage(),
                props = {
                    id: "header-link-" + idx,
                    key: "header-link-" + idx,
                    className: "nav-item",
                };

            switch (item) {
                case SWITCH_MODE: {
                    const linkProps = !checkIfChildrenModeIsActivaded()
                            ? {
                                  className: "nav-link child-mode",
                                  to: "#",
                                  onClick: () => {
                                      if (activePage !== PAGES.HOME && activePage.length !== 1) {
                                          history.pushWithCheck(INDEX_URL + PAGES.HOME);
                                      }
                                      toggleView();
                                      //Using this to update header links
                                      this.setState({});
                                      this.toggleSidebar(null);
                                  },
                              }
                            : {
                                  className: "nav-link child-mode",
                                  to: "#",
                                  onClick: () => {
                                      this.logout(null);
                                  },
                              },
                        toggleLinkText = !checkIfChildrenModeIsActivaded()
                            ? `${this.props.t("header.links.toggle_mode")} ${
                                  selectedProfile?.name ? selectedProfile?.name : ""
                              }`
                            : this.props.t("header.links.parent_mode");
                    itemToReturn = (
                        <li {...props}>
                            <Link {...linkProps}>{toggleLinkText}</Link>
                        </li>
                    );
                    break;
                }
                case PAGES.PRIVACY: {
                    itemToReturn = (
                        <li {...props}>
                            <Link
                                className={"nav-link"}
                                to={"#"}
                                //onClick={() => openUrl(`${this.props?.canteen?.privacy_link}${getCurrentLanguage()}`)}
                                onClick={() =>
                                    getAndOpenStaticPageByUrl(
                                        this.props.canteen.canteen_id,
                                        getCurrentLanguage(),
                                        STATIC_PAGES.PRIVACY,
                                    )
                                }
                            >
                                {this.props.t("header.links.privacy")}
                            </Link>
                        </li>
                    );
                    break;
                }
                case PAGES.TERMS: {
                    itemToReturn = (
                        <li {...props}>
                            <Link
                                className={"nav-link"}
                                to={"#"}
                                //onClick={() => openUrl(`${this.props?.canteen?.privacy_link}${getCurrentLanguage()}`)}
                                onClick={() =>
                                    getAndOpenStaticPageByUrl(
                                        this.props.canteen.canteen_id,
                                        getCurrentLanguage(),
                                        STATIC_PAGES.TERMS,
                                    )
                                }
                            >
                                {this.props.t("header.links.terms")}
                            </Link>
                        </li>
                    );
                    break;
                }
                case PAGES.EDIT_PROFILE: {
                    const data = {
                        id: this.props.user.selectedProfile.id,
                        profile: this.props.user.selectedProfile,
                    };

                    const condition =
                        item === PAGES.HOME
                            ? activePage.includes(item) && activePage.length === 1
                            : activePage.includes(item);

                    const onClick = e => {
                        e.preventDefault();
                        history.pushWithCheck(INDEX_URL + PAGES.EDIT_PROFILE, data);
                    };

                    itemToReturn = (
                        <li {...props}>
                            <Link className={condition ? "nav-link active" : "nav-link"} to={"#"} onClick={onClick}>
                                {this.props.t("header.links.edit-profile")}
                            </Link>
                        </li>
                    );
                    break;
                }
                case PAGES.HOMEPAGE_GAMES: {
                    itemToReturn = (
                        <li {...props}>
                            <Link className={"nav-link"} to={"#"} onClick={() => openUrl(SOHAPPY_GAMES_URL)}>
                                {this.props.t("header.links.homepage_games")}
                            </Link>
                        </li>
                    );
                    break;
                }
                default: {
                    const condition =
                            item === PAGES.HOME
                                ? activePage.includes(item) && activePage.length === 1
                                : activePage.includes(item),
                        linkText = item === PAGES.HOME ? "header.links.home" : `header.links.${item}`,
                        textToUse = this.props.t(linkText);

                    itemToReturn = (
                        <li {...props}>
                            <Link className={condition ? "nav-link active" : "nav-link"} to={item}>
                                {textToUse}
                            </Link>
                        </li>
                    );
                }
            }

            return itemToReturn;
        });
    }

    toggleUserIconClick(e) {
        const newState = {
            userIconClicked: !this.state.userIconClicked,
            dropdownMenuCssClass: dropdownMenuBaseCssClass,
        };

        if (this?.state?.userIconClicked) {
            enableOverflowY();
        } else {
            newState.dropdownMenuCssClass = dropdownMenuActiveCssClass;
            disableOverflowY(true);
        }

        this.setState(newState);
    }

    render() {
        const activePath = window.location.pathname;
        if (
            activePath === INDEX_URL + "registration" ||
            activePath === INDEX_URL + "login" ||
            activePath === INDEX_URL + "reset-password" ||
            activePath === INDEX_URL + "confirmation"
        ) {
            document.removeEventListener("mousedown", this.handleClickOutside);

            return (
                <React.Fragment>
                    <header id="header" className="headerSection fixed no-logged trs">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-center minheight no-gutters">
                                <div className="col-12 text-center align-items-center justify-content-center">
                                    <Link className="navbar-brand navbar-brand-middle ml-auto mr-auto" to={INDEX_URL}>
                                        <img src={logoMiddle} className="img-fluid" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </header>
                </React.Fragment>
            );
        } else if (activePath === INDEX_URL + "create-profile" && this.props.register?.fromRegistration) {
            document.removeEventListener("mousedown", this.handleClickOutside);

            return (
                <React.Fragment>
                    <header id="header" className="headerSection fixed no-logged trs">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-center minheight no-gutters">
                                <div className="col-12 text-center align-items-center justify-content-center">
                                    <Link className="navbar-brand navbar-brand-middle ml-auto mr-auto" to={"#"}>
                                        <img src={logoMiddle} className="img-fluid" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </header>
                </React.Fragment>
            );
        } else {
            document.removeEventListener("mousedown", this.handleClickOutside);
            document.addEventListener("mousedown", this.handleClickOutside);

            const isUserIconEnabled = PAGES_WHERE_SHOW_USER_ICON.includes(activePath);

            return (
                <React.Fragment>
                    <header id="header" className="headerSection fixed trs">
                        <div className="header-row-container minheight">
                            <div className="text-left navbar-img">
                                {getIfLogoCanteenIsVisible() && (
                                    <Link className="navbar-brand" to={INDEX_URL + "home"}>
                                        <img
                                            src={this.props.canteen?.bo_logo}
                                            className="img-fluid d-block"
                                            alt="Canteen logo"
                                        />
                                    </Link>
                                )}
                            </div>
                            <div className="full-item-centered">
                                <Link className="navbar-brand-middle item-centered" to={INDEX_URL + "home"}>
                                    <img src={logoMiddle} className="img-fluid mx-auto d-block" alt="App Logo" />
                                </Link>
                                {getIfSchoolNameUnderLogoIsEnabled() && (
                                    <span className="header-school-name">{this.props.canteen?.name}</span>
                                )}
                            </div>
                            <div className="text-right dropdown-buttons-container">
                                <div
                                    className={
                                        isUserIconEnabled
                                            ? "dropdown account-area"
                                            : "dropdown account-area element-hidden"
                                    }
                                    ref={this.accountAreaRef}
                                >
                                    <Link
                                        className="dropdown-toggle"
                                        to="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        onClick={this.toggleUserIconClick}
                                    >
                                        {getIfAvatarModeIsEnabled() && this.props?.user?.selectedProfile?.avatar ? (
                                            <img
                                                className="avatar-profile-img"
                                                src={getAvatarImage(this.props?.user?.selectedProfile?.avatar)}
                                            />
                                        ) : (
                                            <span className={getProfilesIcon()}>
                                                <span className="path1" />
                                                <span className="path2" />
                                                <span className="path3" />
                                                <span className="path4" />
                                                <span className="path5" />
                                                <span className="path6" />
                                                <span className="path7" />
                                            </span>
                                        )}
                                    </Link>
                                    <div
                                        id="dropdown-list"
                                        className={this.state.dropdownMenuCssClass}
                                        aria-labelledby="dropdownMenuLink"
                                        style={{
                                            height: getWindowSize().height - $(".headerSection").height() + "px",
                                        }}
                                    >
                                        {!getIfToggleSwitchModeIsEnabled() || !checkIfChildrenModeIsActivaded() ? (
                                            this.props?.user?.profiles?.length > 0 ? (
                                                this.props?.user?.profiles?.length > 3 ? (
                                                    <>
                                                        {this.getProfilesHtml(
                                                            this.props?.user?.selectedProfile,
                                                            this.props?.user?.profiles,
                                                        )}
                                                        <CustomLink
                                                            key={"custom-link"}
                                                            text={this.props.t("account.see_all_profiles")}
                                                            onClick={() => {
                                                                enableOverflowY();

                                                                history.pushWithCheck(INDEX_URL + PAGES.ACCOUNT, {
                                                                    focusOnProfiles: true,
                                                                });
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    this.getProfilesHtml(
                                                        this.props?.user?.selectedProfile,
                                                        this.props?.user?.profiles,
                                                    )
                                                )
                                            ) : (
                                                <div>{"No data"}</div>
                                            )
                                        ) : (
                                            <div className="chldren-dropdown-container">
                                                <div className="children-profile-name">
                                                    {this.props.t("header.hello")}{" "}
                                                    {this.props.user.selectedProfile.name}
                                                </div>
                                                {getAvatarHtml(this.props?.user?.selectedProfile?.avatar, this.props.t)}
                                                <label className="change-avatar">
                                                    {this.props.t("header.change_avatar")}
                                                </label>
                                                <AvatarList
                                                    selectedAvatar={this.props.user.selectedProfile.avatar}
                                                    onClick={this.updateAvatarProfile}
                                                />
                                            </div>
                                        )}
                                        {!getIfToggleSwitchModeIsEnabled() || !checkIfChildrenModeIsActivaded() ? (
                                            <>
                                                <Margin marginTop="8px" />
                                                <Link to={"create-profile"}>
                                                    <div className="box-area add-profile">
                                                        <div className="add-icon">
                                                            <span className="icon-plus"></span>
                                                        </div>
                                                        <div className="default-heading">
                                                            <h2>{this.props.t("account.add_profile")}</h2>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="humbarger-arrow-area" onClick={e => this.toggleSidebar(e)}>
                                    <span className="icon-menu-bar">
                                        <span className="path1" />
                                        <span className="path2" />
                                        <span className="path3" />
                                        <span className="path4" />
                                        <span className="path5" />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="sidebar align-content-between">
                            <div className="closed-arrow" onClick={e => this.toggleSidebar(e)}>
                                <span className="icon-close" />
                            </div>
                            <nav className="navbar navbar-expand-md w-100">
                                <div className="navbar-collapse collapse show w-100" id="navbarsExampleDefault">
                                    <ul className="navbar-nav w-100 flex-column">
                                        {this.getHeaderLinks(this.props.user.selectedProfile)}
                                    </ul>
                                </div>
                            </nav>
                            <div className="signout-area" onClick={this.logout}>
                                <Link to="#">
                                    <span className="icon-close-circle" /> {this.props.t("logout")}
                                </Link>
                            </div>
                            <div className="version-sidebar-bottom">
                                <Version color="#fff" />
                            </div>
                        </div>
                    </header>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        setSelectedProfile: async (payload, context) => {
            dispatch({ type: USER_SELECTED_PROFILE, payload });
            await context?.api?.setSelectedProfile(payload ? payload.docId : null);
        },
        updateAvatarProfile: (id, avatar) => dispatch({ type: USER_UPDATE_AVATAR_PROFILE, payload: { id, avatar } }),
        setCanteenData: payload => dispatch({ type: SET_CANTEEN_DATA, payload }),
        logout: () => {
            dispatch({ type: USER_LOGGED_OUT });
            dispatch({ type: DELETE_CANTEEN_DATA });
        },
    };
};

Header.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));
