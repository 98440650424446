// firebase.js
// contains the Firebase context and provider

/**Packages */
import { isEmpty } from "lodash";
import { createContext } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

/**Firebase */
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

/**Redux */
import { SPLASH_VISIBLE, SPLASH_INVISIBLE, SPLASH_CHANGE_VALUE } from "../redux/actions/splashscreenActions";
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../redux/actions/loaderActions";
import {
    USER_LOGGED_IN,
    USER_UPDATE_FIELDS,
    SET_USER_PROFILES,
    USER_SELECTED_PROFILE,
    UPDATE_USER_PROFILE_FAVORITE_RECIPES,
} from "../redux/actions/userActions";
import { SET_CANTEEN_DATA, DELETE_CANTEEN_DATA } from "../redux/actions/canteenActions";
import { FROM_REGISTRATION } from "../redux/actions/registerActions";
import { MENU_RULES_RECEIVED } from "../redux/actions/menuRulesActions";

/**Constants */
import {
    PAGES,
    INDEX_URL,
    FIREBASE_COLLECTIONS,
    LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY,
    CHILDREN_CSS_CLASS,
} from "./../helpers/constants";

/**Utils */
import { toggleView } from "../helpers/view";
import { importCustomCSS } from "../helpers/dynamicCss";
import { getIfRecipeToggleEnabled } from "./../helpers/enviroment";
import { setAttributeToBody, removeAttributeFromBody } from "../helpers/body";
import { getCurrentCanteen, getCurrentCidFromCanteen } from "../helpers/state";
import { getIngredients, getAllergens, getMenuDataRecipeRef } from "./../helpers/menu";
import { addToSessionStorage, getFromSessionStorage } from "./../helpers/sessionStorage";
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from "./../helpers/localStorage";
import {
    checkString,
    getEmailDomain,
    getPreferredProfile,
    getChatsFiltered,
    getCurrentLanguage,
    setCurrentLanguage,
    getCanteenLanguagesFormatted,
    checkIfCurrentLanguageIsEnabled,
    checkIfUserHasProfileWithPassedCid,
} from "./../helpers/utils";
import history from "./../helpers/history";

/**API */
import { api as httpAPI } from "../api";
import { default as config } from "./config";

//Config
const firebaseConfig = config.config;
// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null);
export { FirebaseContext };

const FirebaseHandler = ({ children }) => {
    const dispatch = useDispatch();
    //const { t, i18n } = useTranslation();
    const activateSplash = () => dispatch({ type: SPLASH_VISIBLE });
    const deactivateSplash = () => dispatch({ type: SPLASH_INVISIBLE });
    const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
    const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
    const loggedIn = user => dispatch({ type: USER_LOGGED_IN, payload: user });
    const updateUserFields = user => dispatch({ type: USER_UPDATE_FIELDS, payload: user });
    const setCanteenData = canteenData => dispatch({ type: SET_CANTEEN_DATA, payload: canteenData });
    const setMenuRulesData = menuRulesData => dispatch({ type: MENU_RULES_RECEIVED, payload: menuRulesData });
    const setSplashscreen = splash => dispatch({ type: SPLASH_CHANGE_VALUE, payload: splash });
    const fromRegisterData = data => dispatch({ type: FROM_REGISTRATION, payload: data });
    const setUserProfiles = profiles => dispatch({ type: SET_USER_PROFILES, payload: profiles });
    const setUserPreferredProfile = payload => dispatch({ type: USER_SELECTED_PROFILE, payload });
    const setProfileFavoriteRecipes = (profileId, recipe) =>
        dispatch({ type: UPDATE_USER_PROFILE_FAVORITE_RECIPES, payload: { id: profileId, recipe } });

    let firebase = {
        app: null,
        firestore: null,
        auth: null,
    };

    // check if firebase app has been initialized previously
    // if not, initialize with the config we saved earlier
    if (!app.apps.length) {
        activateSplash();

        app.initializeApp(firebaseConfig);
        firebase = {
            app: app,
            firestore: app.firestore(),
            auth: app.auth(),
            logout,
            api: {
                getToken,
                getMenu,
                getCanteenData,
                getRecipeDataWithId,
                loginWithEmailAndPassword,
                getCurrentUser,
                getChats,
                onSnaspshotChat,
                updateChat,
                changeUserPassword,
                initChat,
                signUpUser,
                signUpUserAnonymously,
                reloadProfiles,
                resetPassword,
                updateNameSurname,
                deleteSignUpProcess,
                toggleRecipeFavorite,
                setSelectedProfile,
                updateProfileName,
                updateProfileAvatar,
                updateProfile,
                onRateRecipes,
                //onRateRecipe,
            },
        };

        onAuthStateChanged();
    }

    async function setFullUserData(uid) {
        const token = await getToken();
        const profilesData = await httpAPI.profile.getProfiles(token);
        const userData = await getUserDataFromFb(uid);
        const profiles = profilesData?.success ? profilesData.data : [];
        const selectedProfile = profilesData?.success ? getPreferredProfile(profilesData.data) : null;
        const canteenData = await getCanteenData(selectedProfile?.cid);
        if (userData) {
            const userTags = userData.tags || [],
                userFormatted = {
                    uid,
                    canteen_id: selectedProfile ? selectedProfile.cid : null,
                    profiles,
                    tags: userTags,
                    selectedProfile,
                    anonymous: userData.anonymous || false,
                    canteens: userData.canteens ? userData.canteens : {},
                    ...userData.profile,
                };

            loggedIn(userFormatted);
            setCanteenData(canteenData);

            if (userFormatted && userFormatted.canteen_id) {
                setAttributeToBody("canteen", userFormatted.canteen_id);
                await httpAPI.session.register(await getToken(), userFormatted.canteen_id);
            }

            setAttributeToBody("tags", userTags.toString());

            if (!selectedProfile || !profiles || profiles.length === 0) {
                const signUpData = {
                    canteen_id: null,
                };

                if (userData && Object.prototype.hasOwnProperty.call(userData, "soMealSignUpProcess")) {
                    signUpData.canteen_id = userData?.soMealSignUpProcess?.cid;
                    await getCanteenData(signUpData.canteen_id);
                }

                fromRegisterData(signUpData);

                history.pushWithCheck(INDEX_URL + PAGES.CREATE_PROFILE);
            } else {
                const newCanteenToAdd = getFromLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY);
                if (newCanteenToAdd && newCanteenToAdd !== "") {
                    const { success: checkUserProfileResult, data: profileFounded } =
                        checkIfUserHasProfileWithPassedCid(userFormatted.profiles, newCanteenToAdd);
                    if (checkUserProfileResult) {
                        const { docId } = profileFounded;
                        removeFromLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY);
                        setUserPreferredProfile(profileFounded);
                        setSelectedProfile(docId);

                        if (profileFounded && profileFounded.cid && profileFounded.cid !== selectedProfile.cid) {
                            await getCanteenData(profileFounded.cid);
                            await httpAPI.session.register(await getToken(), profileFounded.cid);
                        }

                        history.pushWithCheck(INDEX_URL + PAGES.HOME);
                    } else {
                        history.pushWithCheck(INDEX_URL + PAGES.CREATE_PROFILE);
                    }
                } else {
                    if (getFromLocalStorage(CHILDREN_CSS_CLASS) && getFromLocalStorage(CHILDREN_CSS_CLASS) === "true") {
                        toggleView();
                    }
                    history.pushWithCheck(INDEX_URL + PAGES.HOME);
                }
            }
        } else {
            removeAttributeFromBody("tags");
            removeAttributeFromBody("canteen");
        }
    }

    async function deleteSignUpProcess() {
        const uid = await getCurrentUser()?.uid;
        if (uid) {
            await firebase.firestore.collection(FIREBASE_COLLECTIONS.USERS).doc(uid).update({
                soMealSignUpProcess: app.firestore.FieldValue.delete(),
            });
        }
    }

    function updateUserProfileFields(name, surname) {
        const userFormatted = {
            name,
            surname,
        };

        updateUserFields(userFormatted);
    }

    async function updateProfile(profileId, newProfileData) {
        if (!profileId || profileId === "") return;
        if (!newProfileData || isEmpty(newProfileData)) return;

        const uid = getCurrentUser()?.uid;
        if (uid) {
            const result = await firebase.firestore
                .collection(FIREBASE_COLLECTIONS.USERS)
                .doc(uid)
                .collection(FIREBASE_COLLECTIONS.USERS_PROFILES)
                .doc(profileId)
                .set(newProfileData);

            return true;
        }

        return false;
    }

    async function updateProfileName(profileId, newName) {
        if (!profileId || profileId === "") return;
        if (!newName || newName === "") return;

        const uid = getCurrentUser()?.uid;
        if (uid) {
            const result = await firebase.firestore
                .collection(FIREBASE_COLLECTIONS.USERS)
                .doc(uid)
                .collection(FIREBASE_COLLECTIONS.USERS_PROFILES)
                .doc(profileId)
                .update({
                    name: newName,
                });

            return true;
        }

        return false;
    }

    async function updateProfileAvatar(profileId, newAvatar) {
        if (!profileId || profileId === "") return;
        if (!newAvatar || newAvatar === "") return;

        const uid = getCurrentUser()?.uid;
        if (uid) {
            const result = await firebase.firestore
                .collection(FIREBASE_COLLECTIONS.USERS)
                .doc(uid)
                .collection(FIREBASE_COLLECTIONS.USERS_PROFILES)
                .doc(profileId)
                .update({
                    avatar: newAvatar,
                });

            return true;
        }

        return false;
    }

    async function toggleRecipeFavorite(profileId, recipe) {
        let success = false;

        if (
            !profileId ||
            profileId === "" ||
            !recipe ||
            isEmpty(recipe) ||
            !recipe.id ||
            recipe.id === "" ||
            !getIfRecipeToggleEnabled()
        ) {
            return success;
        }

        try {
            const token = await getToken();
            const result = await httpAPI.profile.manageUserProfileRecipesFavorite(token, profileId, recipe);
            if (result?.success) {
                setProfileFavoriteRecipes(profileId, recipe);
                success = true;
            } else {
                console.log("Something went wrong setting the recipes favorite");
            }
        } catch (e) {
            console.log("error in toggleRecipeFavorite");
        } finally {
            return success;
        }
    }

    function onAuthStateChanged() {
        firebase.auth.onAuthStateChanged(async user => {
            activateSplash();

            if (user) {
                const { isAnonymous } = user;
                addToSessionStorage("uid", user.uid);
                addToLocalStorage("uid", user.uid);

                await setFullUserData(user.uid);
            }

            deactivateSplash();
        });
    }

    async function getMenuRules(cid, canteenData = null) {
        if (!cid) return null;

        const cidsRef = [cid];

        if (!canteenData) {
            canteenData = getCurrentCanteen();
            if (!canteenData) {
                canteenData = await getCanteenData(cid);
            }
        }

        if (canteenData && canteenData.parent) {
            cidsRef.push(canteenData.parent);
        }

        const fullMenuRulesFilters = [];

        let nutritionalDataEnabled = true;

        for (let idx = 0; idx < cidsRef.length; idx++) {
            const currentCid = cidsRef[idx];

            const menuRulesDoc = await firebase.firestore
                .collection(FIREBASE_COLLECTIONS.CANTEENS)
                .doc(currentCid)
                .collection(FIREBASE_COLLECTIONS.CANTEEN_RULES)
                .doc(FIREBASE_COLLECTIONS.MENU)
                .get();

            if (menuRulesDoc && menuRulesDoc.exists) {
                const menuRulesData = menuRulesDoc.data();

                if (menuRulesData && menuRulesData.filters) {
                    fullMenuRulesFilters.push(...menuRulesData.filters);
                }

                if (menuRulesData.hasOwnProperty("nutritionalDataEnabled")) {
                    nutritionalDataEnabled = menuRulesData.nutritionalDataEnabled;
                }
            }
        }

        return {
            filters: fullMenuRulesFilters,
            nutritionalDataEnabled,
        };
    }

    async function getMenu(cid, date) {
        if (!cid || cid === "") return null;
        if (!date || date === "") return null;

        const user = firebase.auth.currentUser;

        if (!user) {
            logout();
            return null;
        }

        const menuRef = await firebase.firestore
            .collection(FIREBASE_COLLECTIONS.MENU)
            .doc(cid)
            .collection(FIREBASE_COLLECTIONS.MENU_DATES)
            .doc(date)
            .get();

        if (menuRef.exists) {
            return menuRef.data();
        }

        return null;
    }

    async function getRecipeDataWithId(id = null, canteen_id = null) {
        if (!id || id === "") return null;

        let canteenInternalId = id,
            recipeAdditionalData = null;

        //itsmart, essmart e se vuoi essere sicureo mysdx
        canteenInternalId = canteenInternalId
            .toLowerCase()
            .replace("itsmart", "")
            .replace("essmart", "")
            .replace("mysdx", "");

        const canteenRecipeRef = await firebase.firestore
            .collection(FIREBASE_COLLECTIONS.CANTEENS)
            .doc(canteen_id)
            .collection(FIREBASE_COLLECTIONS.RECIPES)
            .doc(canteenInternalId)
            .get();

        if (canteenRecipeRef.exists) {
            const data = canteenRecipeRef.data();

            recipeAdditionalData = {
                ingredients: getIngredients(data),
                allergens: getAllergens(data),
                description: data.Name,
            };
        } else {
            const recipeRef = await firebase.firestore.collection(FIREBASE_COLLECTIONS.RECIPES).doc(id).get();

            if (recipeRef.exists) {
                const recipeData = recipeRef.data();

                recipeAdditionalData = {
                    ingredients: getIngredients(recipeData),
                    allergens: getAllergens(recipeData),
                    description: recipeData.Name,
                };
            }
        }

        return recipeAdditionalData;
    }

    async function loginWithEmailAndPassword(email, password) {
        if (!email || email === "" || !password || password === "") return null;

        let finalResult = false;
        try {
            const result = await firebase.auth.signInWithEmailAndPassword(email, password);

            if (result && result.user) {
                const {
                    /*
                    emailVerified,
                    email,
                    */
                    uid,
                } = result.user;
                if (uid) {
                    await setFullUserData(uid);
                    finalResult = true;
                } else {
                    finalResult = false;
                }
            }
        } catch (e) {
            console.log(e);
            return null;
        } finally {
            return finalResult;
        }
    }

    async function getUserDataFromFb(uid) {
        if (!uid || uid === "") return null;

        const userRef = await firebase.firestore.collection(FIREBASE_COLLECTIONS.USERS).doc(uid).get();

        if (userRef.exists) {
            return userRef.data();
        }
    }

    function getCurrentUser() {
        return firebase?.auth?.currentUser ? firebase.auth.currentUser : null;
    }

    async function getToken() {
        const result = await firebase.auth.currentUser.getIdToken(/* forceRefresh */ true);
        return result ? result : null;
    }

    function logout() {
        removeFromLocalStorage("uid");
        removeAttributeFromBody("tags");
        removeAttributeFromBody("canteen");
        removeAttributeFromBody("occasion");
        removeAttributeFromBody("diet");
        sessionStorage.clear();
        firebase.auth.signOut();
    }

    async function changeUserPassword(newPassword) {
        if (!getCurrentUser()) return null;

        const userFB = getCurrentUser();
        const result = await userFB
            .updatePassword(newPassword)
            .then(result => {
                // Update successful.
                return true;
            })
            .catch(error => {
                // An error ocurred
                // ...
                console.log("error");
                console.log(error);

                if (error.code === "auth/requires-recent-login") {
                    //toast('Login again to change password');
                    console.log("Login again to change password");
                    logout();
                }
                return false;
            });

        return result;
    }

    async function initChat({ subject, message, name, surname, uid, canteen_id }) {
        const newThread = {
            date: moment().format("YYYY-MM-DD HH:mm:ss"),
            name: name + " " + surname,
            status: 1,
            subject: subject,
            userid: uid,
            canteen_id: canteen_id,
            messages: [
                {
                    date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    from: "U",
                    message: message,
                },
            ],
        };

        if (newThread) {
            const data = await firebase.firestore.collection(FIREBASE_COLLECTIONS.CHAT).add(newThread);
            return data.id ? true : false;
        }

        return null;
    }

    async function signUpUser(profile, cid) {
        if (!checkString(cid) || !profile) return null;

        try {
            cid = typeof cid === "string" && cid.indexOf("C_") !== -1 ? cid.split("_")[1] : cid;

            const canteenResult = await httpAPI.canteen.checkCode(cid);

            if (canteenResult.exists) {
                const { data, check_domain, domains } = canteenResult;
                if (check_domain) {
                    const domain = getEmailDomain(profile.email);
                    if (!domains.includes(domain)) {
                        console.log("L'utente non è abilitato per questa mensa");
                        return null;
                    }
                }
                const resultSignUp = await firebase.auth.createUserWithEmailAndPassword(
                    profile.email,
                    profile.password,
                );

                if (resultSignUp && resultSignUp.user && resultSignUp.user.uid) {
                    delete profile.password;

                    const activeCid = "C_" + cid;

                    const userData = {
                        profile,
                        //active_canteen: activeCid,
                        canteens: {},
                        soMealSignUpProcess: {
                            cid: activeCid,
                        },
                    };

                    const dataAdd = await firebase.firestore
                        .collection(FIREBASE_COLLECTIONS.USERS)
                        .doc(resultSignUp.user.uid)
                        .set(userData)
                        .then(() => {
                            return true;
                        })
                        .catch(() => {
                            return false;
                        });

                    let resultToReturn = null;
                    if (dataAdd) {
                        await getCanteenData(activeCid);
                        fromRegisterData({ canteen_id: activeCid });
                        loggedIn({
                            uid: resultSignUp.user.uid,
                            canteen_id: activeCid,
                            selectedProfile: {},
                            profiles: [],
                            canteens: userData.canteens ? userData.canteens : {},
                            ...userData.profile,
                        });

                        resultToReturn = true;
                    }

                    return resultToReturn;
                } else {
                    console.log("qualcosa è andato storto nella procedura di registrazione");
                    return null;
                }
            } else {
                console.log("La mensa non esiste, qualcosa non va");
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async function signUpUserAnonymously(canteenData = null) {
        try {
            if (
                !getCurrentUser() &&
                (!getFromSessionStorage("uid") || !getFromLocalStorage("uid")) &&
                canteenData &&
                canteenData.auto_login
            ) {
                const resultSignUp = await firebase.auth.signInAnonymously();

                if (resultSignUp && resultSignUp.user && resultSignUp.user.uid) {
                    const userData = {
                        profile: {},
                        active_canteen: canteenData ? canteenData.id : null,
                        canteens: {},
                        anonymous: true,
                    };

                    if (canteenData) {
                        userData.soMealSignUpProcess = {
                            cid: canteenData.id,
                        };
                    }

                    const dataAdd = await firebase.firestore
                        .collection(FIREBASE_COLLECTIONS.USERS)
                        .doc(resultSignUp.user.uid)
                        .set(userData)
                        .then(() => {
                            return true;
                        })
                        .catch(() => {
                            return false;
                        });

                    if (dataAdd) {
                        await getCanteenData(canteenData.id);
                        fromRegisterData({ canteen_id: canteenData.id });
                        loggedIn({
                            uid: resultSignUp.user.uid,
                            canteen_id: canteenData.id,
                            selectedProfile: {},
                            profiles: [],
                            canteens: userData.canteens ? userData.canteens : {},
                            ...userData.profile,
                        });

                        return true;
                    }

                    return false;
                }
            } else {
                console.log("User already logged in or canteen auto_login disabled or canteen data are incorrect");
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async function reloadProfiles(idPreferred = null) {
        const token = await getToken();
        const profilesData = await httpAPI.profile.getProfiles(token);
        if (profilesData.success) {
            const profiles = profilesData.data;
            const selectedProfile = !idPreferred
                ? getPreferredProfile(profiles)
                : profilesData.data.find(profile => {
                      return profile.id === idPreferred;
                  });

            setUserProfiles({
                selectedProfile,
                profiles,
            });

            return selectedProfile?.docId;
        }
    }

    async function resetPassword(email = null) {
        activateLoader();
        try {
            if (!email || email === "" || getEmailDomain(email) === "") return null;

            return await firebase.auth
                .sendPasswordResetEmail(email)
                .then(function () {
                    /*
                    success(
                        //t('reset_password.success')
                        "En breve recibirás un correo electrónico con instrucciones para cambiar la contraseña",
                    );
                    */
                    return true;
                })
                .catch(function (error) {
                    console.log(error.message);
                    return false;
                });
        } catch (e) {
            console.log("error in resetPassword");
            console.log(e);
        } finally {
            deactivateLoader();
        }
    }

    function checkLanguage(languages = []) {
        const canteenLanguages = getCanteenLanguagesFormatted(languages);

        if (!checkIfCurrentLanguageIsEnabled(getCurrentLanguage(), canteenLanguages)) {
            setCurrentLanguage(canteenLanguages[0]);
        }
    }

    async function getCanteenData(cid = null) {
        if (!cid || cid === "") return null;

        let fullCanteenID = cid;
        if (cid.indexOf("C_") === -1) {
            fullCanteenID = "C_" + cid;
        }

        const canteenSnap = await firebase.firestore.collection(FIREBASE_COLLECTIONS.CANTEENS).doc(fullCanteenID).get();
        if (canteenSnap && canteenSnap.exists) {
            setAttributeToBody("canteen", fullCanteenID);
            const canteenData = canteenSnap.data();
            if (canteenData) {
                const data = {
                    canteen_id: canteenData.canteen_id,
                    /**
                     * privacy[0] -> privacy text
                     * privacy[1] -> terms text
                     */
                    privacy: canteenData.privacy,
                    privacy_link:
                        canteenData.links &&
                        canteenData.links.length > 0 &&
                        canteenData.links[6] &&
                        canteenData.links[6].link
                            ? canteenData.links[6].link
                            : null,
                    terms_link:
                        canteenData.links &&
                        canteenData.links.length > 0 &&
                        canteenData.links[7] &&
                        canteenData.links[7].link
                            ? canteenData.links[7].link
                            : null,
                    languages: canteenData.languages && canteenData.languages != [] ? canteenData.languages : [],
                    address: canteenData.address,
                    name: canteenData.name ? canteenData.name : "",
                    splash: canteenData.splash ? canteenData.splash : "",
                    bo_logo: canteenData.bo_logo ? canteenData.bo_logo : process.env.REACT_APP_BASE_CANTEEN_IMAGE_URL,
                    //Preserving old version directly from canteen only for compatibility
                    nutritionalDataEnabled: canteenData.hasOwnProperty("nutritionalDataEnabled")
                        ? canteenData.nutritionalDataEnabled
                        : true,
                };

                const menuRulesData = await getMenuRules(cid, canteenData);

                if (menuRulesData) {
                    setMenuRulesData(menuRulesData.filters);

                    //Moved under correct section on 10/07/2024
                    //Preserving old version directly from canteen only for compatibility
                    if (menuRulesData.hasOwnProperty("nutritionalDataEnabled")) {
                        data.nutritionalDataEnabled = menuRulesData.nutritionalDataEnabled;
                    }
                } else {
                    setMenuRulesData([]);
                }

                /**
                 * Checking if current language is enabled
                 */
                checkLanguage(data?.languages);
                importCustomCSS(data.canteen_id);

                setCanteenData(data);
                setSplashscreen(data.splash);
            }
        } else {
            removeAttributeFromBody("canteen");
        }
    }

    async function getChats(current_canteen_id) {
        const currentUser = await getCurrentUser(),
            userUidProp = "userid",
            orderByCondition = ["date", "desc"],
            condition = "==";
        if (currentUser) {
            let chats = [];
            try {
                const uid = currentUser.uid;
                const chatsSnap = await firebase.firestore
                    .collection(FIREBASE_COLLECTIONS.CHAT)
                    .where(userUidProp, condition, uid)
                    .orderBy(orderByCondition[0], orderByCondition[1])
                    .get();

                if (!chatsSnap.empty) {
                    chats = chatsSnap.docs.map(itm => {
                        return {
                            id: itm.id,
                            data: itm.data(),
                        };
                    });
                    const chatsFiltered = getChatsFiltered(chats, current_canteen_id);
                    chats = [...chatsFiltered];
                }
            } catch (e) {
                console.log("e");
                console.log(e);
            } finally {
                return chats;
            }
        }

        return [];
    }

    async function onSnaspshotChat(callback = () => {}, chatId) {
        const chatSnap = await firebase.firestore
            .collection(FIREBASE_COLLECTIONS.CHAT)
            .doc(chatId)
            .onSnapshot(doc => {
                const chat = doc.data();
                const messages = chat.messages;
                callback(chat, messages);
            });

        return chatSnap;
    }

    async function updateChat(id, chat) {
        let isOk = false;
        try {
            await firebase.firestore.collection(FIREBASE_COLLECTIONS.CHAT).doc(id).set(chat);
            isOk = true;
        } catch (e) {
            isOk = false;
        } finally {
            return isOk;
        }
    }

    async function updateNameSurname(name = null, surname = null) {
        if (!name || !surname || name === "" || surname === "") return;

        let finalResult = false;

        try {
            const currentUser = await getCurrentUser();
            if (currentUser && currentUser.uid) {
                const fullUserData = await getUserDataFromFb(currentUser.uid);
                const uid = currentUser.uid,
                    userRef = firebase.firestore.collection(FIREBASE_COLLECTIONS.USERS).doc(uid),
                    dataToUpdate = {
                        profile: {
                            ...fullUserData.profile,
                            name,
                            surname,
                        },
                    };
                const result = await userRef
                    .update(dataToUpdate)
                    .then(() => {
                        return true;
                    })
                    .catch(() => {
                        return false;
                    });
                if (result) {
                    updateUserProfileFields(name, surname);
                    finalResult = result;
                } else {
                    console.log("something went wrong in updating user data");
                }
            }
        } catch (e) {
            console.log("something went wrong");
            console.log(e);
        } finally {
            return finalResult;
        }
    }

    async function setSelectedProfile(docId = null) {
        if (!docId || docId == "") return;

        try {
            const token = await getToken();
            const response = await httpAPI.profile.updateUserProfileFavorite(token, docId);

            if (response.success) {
                console.log("User profile updated correctly");
                httpAPI.session.register(token, getCurrentCidFromCanteen());
            } else {
                console.log(response.message);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function rateUnderMenu(
        key = null,
        recipeId = null,
        day = null,
        occasionId = null,
        dietId = null,
        courseId = null,
        docMenuRecipeRef = null,
        docRef = null,
        timeoutMultiplier = 1,
    ) {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                let action = "error generical";

                try {
                    if (docMenuRecipeRef.exists) {
                        action = await firebase.firestore
                            .runTransaction(async transaction => {
                                let result = "";

                                const sfDoc = await transaction.get(docRef),
                                    menuData = sfDoc.data(),
                                    {
                                        success,
                                        data: menuArrIdxData,
                                        message,
                                    } = getMenuDataRecipeRef(menuData, occasionId, dietId, courseId, recipeId);

                                if (success) {
                                    const { occasionArrIndex, dietArrIndex, courseArrIndex, recipeArrIndex } =
                                        menuArrIdxData;

                                    let updateMenuData = false;

                                    if (
                                        //case never rate the recipe in this day
                                        !Object.prototype.hasOwnProperty.call(
                                            menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                                courseArrIndex
                                            ].recipes[recipeArrIndex],
                                            "rating",
                                        )
                                    ) {
                                        menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                            courseArrIndex
                                        ].recipes[recipeArrIndex].rating = {
                                            [key]: {
                                                counter: 1,
                                            },
                                        };

                                        updateMenuData = true;
                                    } else if (
                                        //case never rate with this value the recipe in this day, but already has at least one vote
                                        !Object.prototype.hasOwnProperty.call(
                                            menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                                courseArrIndex
                                            ].recipes[recipeArrIndex].rating,
                                            key,
                                        )
                                    ) {
                                        menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                            courseArrIndex
                                        ].recipes[recipeArrIndex].rating[key] = {
                                            counter: 1,
                                        };

                                        updateMenuData = true;
                                    } else {
                                        //update the counter
                                        menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                            courseArrIndex
                                        ].recipes[recipeArrIndex].rating[key].counter =
                                            menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[
                                                courseArrIndex
                                            ].recipes[recipeArrIndex].rating[key].counter + 1;

                                        updateMenuData = true;
                                    }

                                    if (updateMenuData) {
                                        transaction.update(docRef, menuData);
                                        result = "success";
                                    }
                                } else {
                                    result = "error: " + message;
                                }

                                return Promise.resolve(result);
                            })
                            .then(dataTrans => {
                                return `Transaction committed successfully: ${dataTrans}`;
                            })
                            .catch(error => {
                                return `Transaction failed: ${error}`;
                            });
                    } else {
                        console.log(`Impossible to save rate of recipe ${recipeId} in this day: ${day}`);
                    }
                } catch (e) {
                    //console.log("error in rateUnderMenu");
                    //console.log(e);
                    action = "error: " + e.toString();
                } finally {
                    return resolve(action);
                }
            }, timeoutMultiplier * 1000);
        });
    }

    async function rateUnderCanteen(key = null, canteenId = null, recipeId = null, docRef = null) {
        let action = "Operation";

        if (!key || !canteenId || !recipeId || !docRef) return action;

        try {
            if (docRef.exists) {
                const recipeData = docRef.data(),
                    rateRecipeData = recipeData && recipeData.rating ? recipeData.rating : null;

                //Has already al least one vote
                if (rateRecipeData) {
                    if (!Object.prototype.hasOwnProperty.call(rateRecipeData, key)) {
                        rateRecipeData[key] = {
                            counter: app.firestore.FieldValue.increment(1),
                        };
                        //Saving in canteen/{cid}/recipes/{recipeId}
                        firebase.firestore
                            .collection(FIREBASE_COLLECTIONS.CANTEENS)
                            .doc(canteenId)
                            .collection(FIREBASE_COLLECTIONS.RECIPES)
                            .doc(recipeId)
                            .update({
                                rating: rateRecipeData,
                            });

                        action += " successfully committed";
                    } else {
                        const keyWhereToSave = `rating.${key}.counter`;
                        //Saving in canteen/{cid}/recipes/{recipeId}
                        firebase.firestore
                            .collection(FIREBASE_COLLECTIONS.CANTEENS)
                            .doc(canteenId)
                            .collection(FIREBASE_COLLECTIONS.RECIPES)
                            .doc(recipeId)
                            .update({
                                [keyWhereToSave]: app.firestore.FieldValue.increment(1),
                            });

                        action += " successfully committed";
                    }
                }
            } else {
                //Never voted
                const newData = {
                    [key]: {
                        counter: app.firestore.FieldValue.increment(1),
                    },
                };

                //Saving in canteen/{cid}/recipes/{recipeId}
                firebase.firestore
                    .collection(FIREBASE_COLLECTIONS.CANTEENS)
                    .doc(canteenId)
                    .collection(FIREBASE_COLLECTIONS.RECIPES)
                    .doc(recipeId)
                    .set({
                        rating: newData,
                    });

                action += " successfully committed";
            }
        } catch (e) {
            //console.log("error in rateUnderCanteen");
            //console.log(e);
            action += ` error: ${e.toString()}`;
        } finally {
            return action;
        }
    }

    /**
     *
     *
     * data = { recipeId, vote }
     */
    async function onRateRecipe(
        data,
        canteenId = null,
        day = null,
        occasion = null,
        diet = null,
        course = null,
        timeoutMultiplier = 1,
    ) {
        let out = null;

        if (!data || !canteenId || !day) return;

        try {
            const key = `v_${data.vote}`,
                docCanteenRecipeRef = await firebase.firestore
                    .collection(FIREBASE_COLLECTIONS.CANTEENS)
                    .doc(canteenId)
                    .collection(FIREBASE_COLLECTIONS.RECIPES)
                    .doc(data.recipeId)
                    .get(),
                docMenuRef = firebase.firestore
                    .collection(FIREBASE_COLLECTIONS.MENU)
                    .doc(canteenId)
                    .collection(FIREBASE_COLLECTIONS.MENU_DATES)
                    .doc(day),
                docMenuRecipeData = await docMenuRef.get();

            const promises = [
                rateUnderCanteen(key, canteenId, data.recipeId, docCanteenRecipeRef),
                rateUnderMenu(
                    key,
                    data.recipeId,
                    day,
                    occasion.occasion_id,
                    diet.diet_id,
                    course.id,
                    docMenuRecipeData,
                    docMenuRef,
                    timeoutMultiplier,
                ),
            ];

            out = await Promise.all(promises);
        } catch (e) {
            //console.log("error in onRateRecipe");
            //console.log(e);
        } finally {
            return out;
        }
    }

    async function onRateRecipes(recipes = [], canteenId = null, day = null, occasion = null, diet = null) {
        const dataToReturn = {
            success: false,
            data: {},
        };

        if (!recipes || !canteenId || !day) return dataToReturn;

        try {
            const promisesToAwait = recipes.map(async (recipe, idx) => {
                return onRateRecipe(
                    recipe,
                    canteenId,
                    day,
                    occasion,
                    diet,
                    {
                        id: recipe.course_id,
                        name: recipe.course_name,
                    },
                    idx,
                );
            });

            (await Promise.all(promisesToAwait)).forEach((itm, idx) => {
                dataToReturn.data[recipes[idx].recipeId.toString()] = {
                    rateCanteen: itm[0],
                    rateMenu: itm[1],
                };
            });

            dataToReturn.success = true;
        } catch (e) {
            console.log("error in onRateRecipes");
            console.log(e);
        } finally {
            return dataToReturn;
        }
    }

    return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export default FirebaseHandler;
