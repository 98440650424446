/**
 *
 * @param {*} assetStr - asset name
 * @param {*} type - assets type
 * @returns asset scr formatted
 */
export const getAssets = (assetStr, type = "image") => {
    if (!assetStr || assetStr === "") return null;

    switch (type) {
        case "image": {
            return "./images/" + process.env.REACT_APP_IMAGE_PATH_PREFIX + assetStr;
        }
    }
};

/**
 * Used to import images
 */
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}

//const AVATAR_FOLDER = "../images/avatar";

/*
  console.log("name1");
  console.log(name1);
  console.log("name2");
  console.log(name2);
  console.log("name1 < name2 ? -1 : name1 > name2 ? 1 : 0");
  console.log(name1 < name2 ? -1 : name1 > name2 ? 1 : 0);
  */
const orderAvatarImage = (fullName1, fullName2) => {
    const name1 = fullName1.split(".")[0].toLowerCase();
    const name2 = fullName2.split(".")[0].toLowerCase();
    return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
};

export const AVATAR_IMAGES = importAll(require.context("../images/avatar", false, /\.(png|jpe?g|svg)$/));
export const AVATAR_IMAGES_KEYS = AVATAR_IMAGES ? Object.keys(AVATAR_IMAGES).sort(orderAvatarImage) : [];

export const getAvatarImage = key => {
    if (!key || key === "") return null;

    return AVATAR_IMAGES[key].default;
};
