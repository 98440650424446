/**Helpers */
import { PAGES, SWITCH_MODE } from "./constants";
import { checkIfChildrenModeIsActivaded } from "./view";

/**
 *
 * @returns The enviroment (it, es ecc.)
 */
export const getEnvironment = () => {
    return process.env.REACT_APP_ENVIROMENT;
};
/**
 *
 * @returns The icon profile by enviroment
 */
export const getProfilesIcon = () => {
    switch (getEnvironment()) {
        case "ita":
        case "it": {
            return "icon-profiles";
        }
        case "es":
        default: {
            return "icon-user";
        }
    }
};
/**
 *
 * @returns Get links used in right menù by enviroment
 */
export const getHeaderLinksByEnviroment = () => {
    switch (getEnvironment()) {
        case "ita":
        case "it": {
            let links = [];

            if (!checkIfChildrenModeIsActivaded()) {
                links = [
                    PAGES.HOME,
                    PAGES.NEWS,
                    PAGES.HOMEPAGE_GAMES,
                    PAGES.ACTIVITIES,
                    PAGES.MAIL_BOX,
                    PAGES.MESSAGES,
                    PAGES.ALLERGENS,
                    PAGES.ACCOUNT,
                    PAGES.PRIVACY,
                    PAGES.TERMS,
                ];
            } else {
                links = [
                    PAGES.HOME,
                    PAGES.NEWS,
                    PAGES.HOMEPAGE_GAMES,
                    PAGES.ACTIVITIES,
                    PAGES.FAVORITES_RECIPES,
                    PAGES.CHANGE_AVATAR,
                    PAGES.PRIVACY,
                    PAGES.TERMS,
                ];
            }

            if (getIfToggleSwitchModeIsEnabled()) {
                links.push(SWITCH_MODE);
            }

            return links;
        }
        case "es":
        default: {
            return [
                PAGES.ACCOUNT,
                PAGES.HOME,
                PAGES.NEWS,
                PAGES.MAIL_BOX,
                PAGES.MESSAGES,
                PAGES.CREATE_PROFILE,
                PAGES.PRIVACY,
            ];
        }
    }
};
/**
 *
 * @returns The string key for download pdf by enviroment
 */
export const getDownloadKeyString = () => {
    switch (getEnvironment()) {
        case "ita":
        case "it": {
            return "download.menu";
        }
        case "es":
        default: {
            return "download.text";
        }
    }
};
/**
 *
 * @returns If account add button in account page is enabled or not by enviroment
 */
export const getIfAddAccountEnabledInAccountPage = () => {
    switch (getEnvironment()) {
        case "ita":
        case "it": {
            return true;
        }
        case "es":
        default: {
            return false;
        }
    }
};
/**
 *
 * @returns If recommendations image is enabled or not by enviroment
 */
export const getIfImageRecommendationsEnabled = () => {
    /**Before for ita was true */
    switch (getEnvironment()) {
        case "ita":
        case "it": {
            return false;
        }
        case "es":
        default: {
            return false;
        }
    }
};
/**
 *
 * @returns If Recipe Detail page and onClick feature is enabled or not
 */
export const getIfRecipeDetailEnabled = () => {
    return process.env.REACT_APP_RECIPE_DETAIL_ENABLED === "true";
};
/**
 *
 * @returns If Recipe Detail page and onClick feature is enabled or not
 */
export const getIfModifyNameIsEnabled = () => {
    return process.env.REACT_APP_MODIFY_PROFILES_NAME === "true";
};
/**
 *
 * @returns If Avatar are enabled or not
 */
export const getIfAvatarModeIsEnabled = () => {
    return process.env.REACT_APP_AVATAR_MODE === "true";
};
/**
 *
 * @returns If Toggle mode is enabled or not
 */
export const getIfToggleSwitchModeIsEnabled = () => {
    return process.env.REACT_APP_TOGGLE_SWITCH_PROFILE_MODE === "true";
};
/**
 *
 * @returns If Asterisk string is enabled or not
 */
export const getIfMenuAsteriskIsEnabled = () => {
    return process.env.REACT_APP_RECIPE_ASTERISK_ENABLED === "true";
};
/**
 *
 * @returns If User image message is enabled or not
 */
export const getIfUseImageMessage = () => {
    return process.env.REACT_APP_USE_IMAGE_MESSAGE === "true";
};
/**
 *
 * @returns If use image download or not
 */
export const getIfUseImageDownload = () => {
    return process.env.REACT_APP_USE_IMAGE_DOWNLOAD === "true";
};
/**
 *
 * @returns If use user simplify version (only year and name) or not
 */
export const getIfUseProfileSimplifyVersion = () => {
    return process.env.REACT_APP_USE_PROFILE_SIMPLIFY_VERSION === "true";
};
/**
 *
 * @returns If recipe favorite toggle is enabled or not
 */
export const getIfRecipeToggleEnabled = () => {
    return process.env.REACT_APP_RECIPE_FAVORITE_TOGGLE_ENABLED === "true";
};
/**
 *
 * @returns If News direct link from home is enabled or not
 */
export const getIfNewsDirectLinkEnabled = () => {
    return process.env.REACT_APP_NEWS_HOME_DIRECT_LINK_ENABLED === "true";
};
/**
 *
 * @returns If new custom select for occasion is enabled or not
 */
export const getIfNewOccasionSelectionIsEnabled = () => {
    return process.env.REACT_APP_USE_OCCASION_CUSTOM_SELECT === "true";
};
/**
 *
 * @returns If pdf will be downloaded with all occasions or not
 */
export const getIfDownloadPdfWithAllOccasionsIsEnabled = () => {
    return process.env.REACT_APP_DOWNLOAD_PDF_WITH_ALL_OCCASIONS === "true";
};
/**
 *
 * @returns If background news randomic is enabled
 */
export const getIfRandomicNewsBackgroundIsEnabled = () => {
    return process.env.REACT_APP_RANDOMIC_BACKGROUND_NEWS === "true";
};
/**
 *
 * @returns If anonymous login is enabled
 */
export const getIfSignInAnonymouslyIsEnabled = () => {
    return process.env.REACT_APP_SIGN_IN_ANONYMOUS === "true";
};
/**
 *
 * @returns If school name under logo is enabled
 */
export const getIfSchoolNameUnderLogoIsEnabled = () => {
    return process.env.REACT_APP_SCHOOL_NAME_UNDER_HEADER_LOGO === "true";
};
/**
 *
 * @returns If parents can rate recipes
 */
export const getIfParentRatingRecipesIsEnabled = () => {
    return process.env.REACT_APP_PARENT_RATING_RECIPES === "true";
};
/**
 *
 * @returns If children can rate recipes
 */
export const getIfChildrenRatingRecipesIsEnabled = () => {
    return process.env.REACT_APP_CHILDREN_RATING_RECIPES === "true";
};
/**
 *
 * @returns Check if rate recipe button and modal are enabled
 */
export const checkIfRateRecipeIsEnabled = () => {
    return (
        (!checkIfChildrenModeIsActivaded() && getIfParentRatingRecipesIsEnabled()) ||
        (checkIfChildrenModeIsActivaded() && getIfChildrenRatingRecipesIsEnabled())
    );
};
/**
 *
 * @returns Min validated canteen code (number)
 */
export const getBaseCanteenPrivacyTermsUrl = () => {
    return process.env.REACT_APP_BASE_PRIVACY_CANTEEN;
};
/**
 *
 * @returns Min validated canteen code (number)
 */
export const getMinCanteenIDNumber = () => {
    return parseInt(process.env.REACT_APP_MIN_CANTEEN_ID);
};
/**
 *
 * @returns Max validated canteen code (number)
 */
export const getMaxCanteenIDNumber = () => {
    return parseInt(process.env.REACT_APP_MAX_CANTEEN_ID);
};
/**
 *
 * @returns true if logo canteen is visible, otherwise false
 */
export const getIfLogoCanteenIsVisible = () => {
    return process.env.REACT_APP_LOGO_CANTEEN_VISIBLE === "true";
};
/**
 *
 * @returns maximum number of news in home
 */
export const getMaxNumberOfNewsInHome = () => {
    return parseInt(process.env.REACT_APP_N_MAX_NEWS_HOME || 4);
};
/**
 *
 * @returns true if gtm and ga are enabled, otherwise false
 */
export const getIfGTMGAEnabled = () => {
    return process.env.REACT_APP_GTM_GA_ENABLED === "true";
};
/**
 *
 * @returns Application name if exists
 */
export const getApplicationName = () => {
    return process.env.REACT_APP_APPLICATION_NAME;
};
/**
 *
 * @returns {boolean} true if SoHappy, otherwise false
 */
export const getIfSoHappy = () => {
    return getApplicationName()?.toLowerCase().includes("sohappy");
};
/**
 *
 * @returns {boolean} true if SoMeal, otherwise false
 */
export const getIfSoMeal = () => {
    return getApplicationName()?.toLowerCase().includes("someal");
};
