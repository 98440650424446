import { createRef } from "react";
import moment from "moment";
import $ from "jquery";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Utils */
import { SITE_TITLE } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import { checkString } from "../../helpers/utils";
import { enableOverflowY, disableOverflowY } from "../../helpers/overflow";
import { success, error } from "../../helpers/toast";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**Components */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**SCSS */
import "./_chat.scss";

const getListMessagesHtml = (messages, t) => {
    if (!messages || messages.length === 0) return null;

    return messages.map((message, idx) => {
        const { message: text, from } = message;
        const className = from === "BO" ? "message-container left" : "message-container right";
        const fullText =
            from === "BO"
                ? `<b>${t("chat.bo_interpreter")}:</b>` + text
                : `<b>${t("chat.you_interpreter")}:</b>` + text;
        return <div key={"message-" + idx} className={className} dangerouslySetInnerHTML={{ __html: fullText }} />;
    });
};

const scrollToBottom = ref => {
    if (ref?.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
    }
};

const getMasterContainerHeight = () => {
    const headerClassName = ".headerSection",
        constantHeight = 0;

    //const bodyHeight = document.body.style.getPropertyValue("height");
    const bodyHeight = $("body").height() ? $("body").height() : 0;
    const headerHeight = $(headerClassName).height() ? $(headerClassName).height() : 0;

    return bodyHeight - (headerHeight + constantHeight) + "px";
};

const getChatListHeight = () => {
    const headerClassName = ".headerSection",
        messageHeadAreaClassName = ".messages-head-area",
        sendMessageContainerClassName = ".send-message-container",
        constantHeight = 20;

    //const bodyHeight = document.body.style.getPropertyValue("height");
    const bodyHeight = $("body").height() ? $("body").height() : 0;
    const headerHeight = $(headerClassName).height() ? $(headerClassName).height() : 0;
    const messageHeadAreaHeight = $(messageHeadAreaClassName).height() ? $(messageHeadAreaClassName).height() : 0;
    const sendMessageContainerHeight = $(sendMessageContainerClassName).height()
        ? $(sendMessageContainerClassName).height()
        : 0;

    return bodyHeight - (headerHeight + messageHeadAreaHeight + sendMessageContainerHeight + constantHeight) + "px";
};

class Chat extends Back {
    constructor(props) {
        super(props);

        this.state = {
            //chat: props?.location?.state?.chat,
            chat: null,
            id: props?.location?.state?.id,
            chatSubsribe: null,
            newMessageText: "",
        };

        this.sendMessage = this.sendMessage.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.textareaMessageRef = createRef();
        this.divMessageContainerRef = createRef();
    }

    onChange(e, label, checkContent = false, typeOfToCheck = null) {
        if (label && label !== "") {
            const newState = this.state;
            const value = e.target && e.target.value && e.target.value !== "" ? e.target.value : "";
            if (typeof value === "string") {
                newState[label] = value;
                this.setState(newState);
            } else {
                console.log(`Per la ${label} nessun valore`);
            }
        } else {
            console.log(`${label} errore`);
        }
    }

    async componentDidMount() {
        const self = this;

        if (!(this.state.chat || this.state.id)) {
            this.goBack();
            return;
        }

        window.addEventListener("resize", this.updateDimensions);

        const callback = (chat, messages, chatSubsribe) => {
            self.props.activateLoader();
            self.setState(
                {
                    chat,
                    //chatSubsribe,
                },
                () => {
                    self.props.deactivateLoader();
                    this.divMessageContainerRef.current.style.height = getChatListHeight();
                    this.divMessageContainerRef.current.style.maxHeight = getChatListHeight();
                    scrollToBottom(this.divMessageContainerRef);
                },
            );
        };

        const chatSubsribe = await this?.context?.api?.onSnaspshotChat(callback, this.state.id);

        self.setState(
            {
                chatSubsribe,
            },
            () => {
                scrollToBottom(this.divMessageContainerRef);
            },
        );
    }

    updateDimensions() {
        this.divMessageContainerRef.current.style.height = getChatListHeight();
        this.divMessageContainerRef.current.style.maxHeight = getChatListHeight();
    }

    componentWillUnmount() {
        if (this.state.chatSubsribe) {
            this.state.chatSubsribe();
        }

        window.removeEventListener("resize", this.updateDimensions);
    }

    onTextAreaFocus(e) {
        enableOverflowY();
    }

    onTextAreaBlur(e) {
        disableOverflowY();
    }

    async sendMessage(e) {
        e.preventDefault();
        if (checkString(this.state.newMessageText)) {
            const id = this.state.id,
                thread = Object.assign({}, this.state.chat),
                from = "U";
            thread.messages.push({
                date: moment().format("YYYY-MM-DD HH:mm:ss"),
                message: this.state.newMessageText,
                from: from,
            });
            if (id && id != "") {
                await this.context.api.updateChat(id, thread);
                this.setState(
                    {
                        chat: thread,
                        newMessageText: "",
                    },
                    () => {
                        scrollToBottom(this.divMessageContainerRef);
                    },
                );
            }
        } else {
            error(this.props.t("error_parameters"));
        }
    }

    render() {
        const { t } = this.props;
        const { subject, messages } = this.state?.chat ? this.state?.chat : [];
        return (
            <>
                <Header />
                <div className="outer-container col-12 middle-content">
                    <GoBackPlusTitle
                        onClick={this.goBack}
                        goBackText={t("chat.back")}
                        title={checkString(subject) ? subject : t("chat.title")}
                    />
                    <div className="col-12 col-sm-10 col-md-8 chat-list-container" ref={this.divMessageContainerRef}>
                        {getListMessagesHtml(messages, this.props.t)}
                    </div>
                    <div className="col-12 col-sm-10 col-md-8 send-message-container">
                        <textarea
                            ref={this.textareaMessageRef}
                            className="textarea-message"
                            id="chat_textarea_message"
                            /*
                            {
                                this.state.newMessageText +
                                getChatListHeight() +
                                " " +
                                this?.divMessageContainerRef?.current?.style?.height
                            }
                            */
                            value={this.state.newMessageText}
                            onFocus={e => this.onTextAreaFocus(e)}
                            onBlur={e => this.onTextAreaBlur(e)}
                            onChange={e => this.onChange(e, "newMessageText")}
                        />
                        <div className="textarea-button clickable" onClick={e => this.sendMessage(e)}>
                            <i className="fas fa-paper-plane"></i>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

Chat.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Chat));
